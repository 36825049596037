@font-face {
  font-family: Typold;
  src: url(/assets/font/Typold-Book500.woff2) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 100;
  src: url(/assets/font/PublicSans-Thin.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 200;
  src: url(/assets/font/PublicSans-ExtraLight.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 300;
  src: url(/assets/font/PublicSans-Light.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 400;
  src: url(/assets/font/PublicSans-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 500;
  src: url(/assets/font/PublicSans-Medium.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 600;
  src: url(/assets/font/PublicSans-SemiBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 700;
  src: url(/assets/font/PublicSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 800;
  src: url(/assets/font/PublicSans-ExtraBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Public Sans;
  font-weight: 900;
  src: url(/assets/font/PublicSans-Black.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 100;
  src: url(/assets/font/WorkSans-Thin.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 200;
  src: url(/assets/font/WorkSans-ExtraLight.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 300;
  src: url(/assets/font/WorkSans-Light.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 400;
  src: url(/assets/font/WorkSans-Regular.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 500;
  src: url(/assets/font/WorkSans-Medium.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 600;
  src: url(/assets/font/WorkSans-SemiBold.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 700;
  src: url(/assets/font/WorkSans-Bold.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 800;
  src: url(/assets/font/WorkSans-ExtraBold.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Work Sans;
  font-weight: 900;
  src: url(/assets/font/WorkSans-Black.woff2) format("woff2"),
    url(/assets/font/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/font/OpenSans-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/font/MaterialIcons.woff2) format("woff2");
}
